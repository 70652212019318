export const nftData = [
    "bapetaverse-official",
    "0n1-force",
    "10ktf",
    "10ktf-stockroom",
    "3landers",
    "8liens",
    "animo-official",
    "adam-bomb-squad",
    "adidasoriginals",
    "akumaorigins",
    "akumu-dragonz",
    "akutars",
    "alienfrensnft",
    "alpacadabraz",
    "alphadogs",
    "animetas",
    "anonymice",
    "anonymice-babies",
    "antonymgenesis",
    "ape-gang",
    "arcade-land",
    "asm-aifa-genesis",
    "autoglyphs",
    "azuki",
    "bagnerwtf",
    "beanzofficial",
    "bears-deluxe",
    "bff-friendship-bracelets",
    "blu3prints",
    "bobutoken",
    "bokinftofficial",
    "boonjiproject",
    "bored-and-dangerous",
    "bored-ape-kennel-club",
    "boredapeyachtclub",
    "bossbeauties",
    "byopill",
    "c-01-official-collection",
    "capsulehouse",
    "catbloxgenesis",
    "chain-runners-nft",
    "chimpersnft",
    "chromie-squiggle-by-snowfro",
    "clayfriends",
    "clonex",
    "genesis-creepz",
    "cool-cats-nft",
    "coolmans-universe",
    "coolpetsnft",
    "cosmiclabs",
    "cosmodinos-omega",
    "cpg-pop",
    "creatureworld",
    "critterznft",
    "the-crypto-chicks",
    "cryptoadz-by-gremplin",
    "cryptobatz-by-ozzy-osbourne",
    "cryptocoven",
    "cryptodickbutts-s3",
    "cryptomories",
    "cryptoongoonz",
    "cryptoskulls",
    "cupcatsofficial",
    "cyberbrokers",
    "cyberkongz",
    "cyberkongz-vx",
    "deadfellaz",
    "deadfrenz-collection",
    "degentoonz-collection",
    "degenz",
    "degenzaccesspass",
    "dentedfeelsnft",
    "desperate-ape-wives",
    "dolce-gabbana-dgfamily",
    "dippies",
    "divineanarchy",
    "doodles-official",
    "the-dooplicator",
    "dourdarcels",
    "dreamlandgenesisproject",
    "duskbreakers",
    "dystoapez-official",
    "edenhorde-official",
    "eightbitme",
    "eponym",
    "ethalien-vox",
    "ether-orcs",
    "fanggangnft",
    "fidenza-by-tyler-hobbs",
    "filaments-art",
    "fishyfam",
    "fluf",
    "fluf-world-thingies",
    "forgottenruneswizardscult",
    "foxfam",
    "fragments-by-james-jean",
    "metacard-by-fullsend",
    "furballs-com-official",
    "fvck-crystal",
    "galacticapes",
    "galacticmonkes",
    "galaxyeggs9999",
    "goblintown",
    "godhatesnftees",
    "goda-mint-pass",
    "g00p",
    "gossamer-seed",
    "grayboys",
    "grifters-by-xcopy",
    "peaceful-groupies",
    "10ktf-gucci-grail",
    "guttercatgang",
    "hapeprime",
    "hashmasks",
    "hausphases",
    "headdao",
    "illuminaticollective",
    "io-imaginary-ones",
    "impostors-genesis-aliens",
    "invisiblefriends",
    "projectisekaimeta",
    "jenkins-the-valet",
    "twinflames",
    "kaiju-failed-experiments",
    "kaiju-kingz",
    "kaijumutant",
    "karafuru",
    "karma-monkey",
    "killabears",
    "killergf",
    "kiwami-genesis",
    "knights-of-degen-official",
    "kumo-x-world-residents",
    "larva-lads",
    "lazy-lions",
    "legend-maps-founder",
    "ledger-market-pass-genesis-edition",
    "lil-heroes-by-edgar-plans",
    "linksdao",
    "little-lemon-friends",
    "livesofasuna",
    "letswalk",
    "loser-club-official",
    "magic-mushroom-clubhouse",
    "mcgoblinwtf",
    "mclarenmsolabgenesis",
    "meebits",
    "mekaverse",
    "meta-legends",
    "metahero-generative",
    "metroverse-genesis",
    "mev-army",
    "mfer",
    "midnightbreeze",
    "milady",
    "mind-the-gap-by-mountvitruvius",
    "mindblowon-universe",
    "minimenlegendary",
    "mirakai-scrolls",
    "official-moar-by-joan-cornella",
    "monftersclub",
    "proof-moonbirds",
    "moonbirds-oddities",
    "moonrunnersnft",
    "mulesquad",
    "murakami-flowers-2022-official",
    "murakami-flowers-seed",
    "murixhaus",
    "mutant-ape-yacht-club",
    "mutantcats",
    "mutantflokiwilljack",
    "mypethooligan",
    "neo-tokyo-identities",
    "neotokyo-outer-identities",
    "nft-worlds",
    "nftiff",
    "niftydegen",
    "nouns",
    "nuclear-nerds-of-the-accidental-apocalypse",
    "official-clementines-nightmare",
    "omgkirby-genesis",
    "onchainmonkey",
    "oncyber-labs",
    "otherdeed",
    "partydegenerates",
    "peaceful-groupies",
    "pegz",
    "persona-lamps",
    "phantabear",
    "pixl-pets-genesis",
    "playsherbet",
    "thepotatoz",
    "premint-collector",
    "primeapeplanetpap",
    "projectnanopass",
    "pxnghostdivision",
    "proof-collective",
    "propertysofficial",
    "psychedelics-anonymous-genesis",
    "pudgypenguins",
    "punkscapes",
    "purrnelopes-country-club",
    "quantum-key",
    "quirkiesoriginals",
    "ragnarok-meta",
    "raidpartyfighters",
    "rareapepeyachtclub",
    "rebelbots",
    "regenz",
    "regulars",
    "rektguy",
    "ringers-by-dmitri-cherniak",
    "robotos-official",
    "rtfkt-mnlth",
    "clonex-mintvial",
    "ruggenesis-nft",
    "rumble-kong-league",
    "sappy-seals",
    "scholarz",
    "theshiboshis",
    "galverse",
    "slimhoods",
    "smilesss",
    "sneakerheadsofficial",
    "sneaky-vampire-syndicate",
    "somethingtoken",
    "spaceridersnft",
    "the-squishiverse",
    "squishy-squad",
    "starcatchersnft",
    "stickmentoys",
    "supducks",
    "super-cool-world",
    "supernormalbyzipcy",
    "swampverseofficial",
    "tableland-rigs",
    "tastybonesxyz",
    "tbac",
    "the-art-of-seasons",
    "the-doge-pound",
    "snoop-dogg-doggies",
    "heartnfts",
    "thepossessed",
    "thesaudis",
    "the-colors-dot-art",
    "the-crypto-chicks",
    "the-doge-pound",
    "the-long-lost",
    "the-pixels-inc",
    "the-wanderers",
    "thecurrency",
    "thehumanoids",
    "theirsverse-official",
    "thelittlesnft",
    "thingdoms",
    "timelessnfts",
    "tiny-dinos-eth",
    "tos-theotherside",
    "toyboogers",
    "toyfrens",
    "trait-sniper-lifetime-access",
    "treeverse-plots",
    "timelessnfts",
    "nftrees",
    "tubby-cats",
    "lacosteundw3",
    "uwucrew",
    "official-v1-punks",
    "vaynersports-pass-vsp",
    "veefriends",
    "veefriends-series-2",
    "visitors-of-imma-degen",
    "wabi-sabi-official",
    "wagmiarmy",
    "wassiesbywassies",
    "we-are-all-going-to-die",
    "webaverse-genesis-pass",
    "the-weirdo-ghost-gang",
    "where-my-vans-go",
    "winterbears",
    "wolf-game",
    "wonderpals",
    "woodies-generative",
    "world-of-women-galaxy",
    "world-of-women-nft",
    "worldwidewebbland",
    "wvrps-by-warpsound",
    "yolo-bunny",
    "zenapenft",
    "zombieclub-token"
]